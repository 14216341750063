var Contact = ( function () {
    return function(){
		
		var configureContact = function(){
			var  utilities = Utilities();
			var applicationForm = $('#contact_form');

			var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

			var notificationDiv = $('#notification_div');
			var submitButton = $('#submit');

			applicationForm.trigger( "required:validate" );

			$(document).on( 'required:validate','#contact_form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#contact_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit', function(e){
                var requiredFields = $('#contact_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                if( !$('#contact_form').valid() ){
                    e.preventDefault();
                }
                else{

                    var submissionUrl = {
                        submit_loading: 1
                    };

                    $('#submit').addClass("nopadding");

                    $('#submit').html(Handlebars.partials['partial_bars'](submissionUrl));

                    setTimeout(function () { $('#submit').attr("disabled","disabled"); }, 0);
                    
                }
            });

            if($('#map2').length){
                $('#map2').show();
                applicationGoogle = Google();
                applicationGoogle.initialize();
            }
		};
        var configureAgentContact = function(){
            var utilities = Utilities();
            var applicationForm = $('#contact_form');

            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

            var notificationDiv = $('#notification_div');
            var submitButton = $('#contact_agent');

            applicationForm.trigger( "required:validate" );

            $(document).on( 'required:validate','#contact_form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#contact_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#contact_agent', function(e){
                var requiredFields = $('#contact_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                if( !$('#contact_form').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });
        };
        var configureLogin = function(){
            var  utilities = Utilities();
            var applicationForm = $('#login_form');

            var applicationForm2 = $('#login_form_details');

            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            var requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

            var notificationDiv = $('#notification_div_login');
            var notificationDiv2 = $('#notification_div_login_details');
            var submitButton = $('#login_button');
            var submitButton2 = $('#login_details');

            applicationForm.trigger( "required:validate" );
            applicationForm2.trigger( "required:validate2" );

            $(document).on( 'required:validate','#login_form', function(){
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#login_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'required:validate2','#login_form_details', function(){
                requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#login_form_details').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#login_button', function(e){
                var requiredFields = $('#login_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div_login'));
                if( !$('#login_form').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });

            $(document).on( 'click','#login_details', function(e){
                var requiredFields2 = $('#login_form_details' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields2,$('#notification_div_login_details'));
                if( !$('#login_form_details').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });

            var applicationFacebook = Facebook();
            applicationFacebook.configureLogin();

            var applicationLinkedIn = LinkedIn();
            applicationLinkedIn.configureLogin();

            var applicationGoogle = Google();
            applicationGoogle.configureLogin();

        };
        var configureForget = function(){
            var  utilities = Utilities();
            var applicationForm = $('#forget_form');
            var applicationForm2 = $('#forget_form_details');

            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            var requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

            var notificationDiv = $('#notification_div_forget');
            var notificationDiv2 = $('#notification_div_forget_details');
            var submitButton = $('#retrieve_password');
            var submitButton2 = $('#recover_password_details');

            applicationForm.trigger( "required:validate" );
            applicationForm2.trigger( "required:validate2" );

            $(document).on( 'required:validate','#forget_form', function(){
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#forget_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'required:validate2','#forget_form_details', function(){
                requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#forget_form_details').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#retrieve_password', function(e){   
                
                var requiredFields = $('#forget_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div_forget'));
                if( !$('#forget_form').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });

            $(document).on( 'click','#recover_password_details', function(e){   
                
                var requiredFields2 = $('#forget_form_details' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields2,$('#notification_div_forget_details'));
                if( !$('#forget_form_details').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });
        };
        var configureSignUp = function(){
            var  utilities = Utilities();
            var applicationForm = $('#sign_up_form');
            var applicationForm2 = $('#sign_up_form_details');

            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            var requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

            var notificationDiv = $('#notification_div_sign_up');
            var notificationDiv2 = $('#notification_div_sign_up_details');
            var submitButton = $('#sign_up_button');
            var submitButton2 = $('#sign_up_button_details');

            applicationForm.trigger( "required:validate" );
            applicationForm2.trigger( "required:validate2" );

            $(document).on( 'required:validate','#sign_up_form', function(){
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#sign_up_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'required:validate2','#sign_up_form_details', function(){
                requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#sign_up_form_details').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#sign_up_button', function(e){
                var requiredFields = $('#sign_up_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                var pushArray = [];

                if( $('#confirm_password_sign_up').val() !== $('#password_sign_up').val() ){
                    pushArray.push("Password and Confirm Password Are Not The Same");
                }

                requiredObject.additional = pushArray;
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div_sign_up'));

                if( !$('#sign_up_form').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });

            $(document).on( 'click','#sign_up_button_details', function(e){
                var requiredFields2 = $('#sign_up_form_details' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                var pushArray = [];

                if( $('#confirm_password_sign_up_details').val() !== $('#password_sign_up_details').val() ){
                    pushArray.push("Password and Confirm Password Are Not The Same");
                }

                requiredObject.additional = pushArray;
                utilities.requiredUlCheck(requiredObject,requiredFields2,$('#notification_div_sign_up_details'));               

                if( !$('#sign_up_form_details').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });

            $('#confirm_password_sign_up').rules("add",{
                required:true,
                equalTo:$('#password_sign_up'),
                messages: {
                    equalTo: "Password and Confirm Password Are Not The Same",
                    required: "Please Enter Your Password Confirmation",
                }
            });
        };
        var configureReset = function(){
            
            var  utilities = Utilities();
            var applicationForm = $('#reset_form');
            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

            var notificationDiv = $('#notification_div');
            var submitButton = $('#submit_reset');

            applicationForm.trigger( "required:validate" );

            $(document).on( 'required:validate','#reset_form', function(){
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#reset_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit_reset', function(e){
                var requiredFields = $('#reset_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                var pushArray = [];

                if( $('#confirm_password_reset').val() !== $('#password_reset').val() ){
                    pushArray.push("Password and Confirm Password Are Not The Same");
                }

                requiredObject.additional = pushArray;
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));

                if( !$('#reset_form').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
                
            });

            $('#confirm_password_reset').rules("add",{
                required:true,
                equalTo:$('#password_reset'),
                messages: {
                    equalTo: "Password and Confirm Password Are Not The Same",
                    required: "Please Enter Your Password Confirmation",
                }
            });            
        };
        var configureProfile = function(){
            var  utilities = Utilities();
            var applicationForm = $('#profile_form');
            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            var notificationDiv = $('#notification_div_profile');
            var submitButton = $('#submit_enquiry_profile');

            applicationForm.trigger( "required:validate" );

            $(document).on( 'required:validate','#profile_form', function(){
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#profile_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit_enquiry_profile', function(e){
                var requiredFields = $('#profile_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                var pushArray = [];

                if( $('#confirm_password_profile').val() !== $('#password_profile').val() ){
                    pushArray.push("Password and Confirm Password Are Not The Same");
                }

                requiredObject.additional = pushArray;
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div_profile'));

                if( !$('#profile_form').valid() ){
                    e.preventDefault();
                }
                else{
                    
                }
            });

            /*
            $('#confirm_password_profile').rules("add",{
                required:true,
                equalTo:$('#password_profile'),
                messages: {
                    equalTo: "Password and Confirm Password Are Not The Same",
                    required: "Please Enter Your Password Confirmation",
                }
            });
            */
        };
        var configureValuation = function(){
            var  utilities = Utilities();
            var applicationForm = $('#valuation-form');

            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

            var notificationDiv = $('#notification_div');
            var submitButton = $('#submit_valuation_request');

            applicationForm.trigger( "required:validate" );

            $(document).on( 'required:validate','#valuation-form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#valuation-form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit_valuation_request', function(e){
                var requiredFields = $('#valuation-form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"}; 

                var pushArray = [];

                var propertyType = 0;  


                $('input[name="property_type[]"]').each(function(){
                    if( $(this).is(':checked') ){
                        propertyType = 1;
                    }
                });

                if(!propertyType){
                    pushArray.push('Property Type');
                }

                requiredObject.additional = pushArray;
                

                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                if( !$('#valuation-form').valid() || !propertyType ){
                    e.preventDefault();
                }
                else{
                    
                }
            });
        };

        var configureFindProperty = function(){
            var  utilities = Utilities();
            var applicationForm = $('#find-property-form');
            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            var notificationDiv = $('#notification_div');
            var submitButton = $('#submit_find_request');

            applicationForm.trigger( "required:validate" );

            $(document).on( 'required:validate','#find-property-form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#find-property-form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });
            
            $(document).on( 'click','#submit_find_request', function(e){
                var requiredFields = $('#find-property-form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      

                var pushArray = [];

                requiredObject.additional = pushArray;


                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                if( !$('#find-property-form').valid() || pushArray.length ){
                    e.preventDefault();
                }
                else{
                    
                }
            });
        };
        var configureListProperty = function(){
            var  utilities = Utilities();
            var applicationForm = $('#list_property_form');
            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            var notificationDiv = $('#notification_div');
            var submitButton = $('#submit_list_my_property');

            applicationForm.trigger( "required:validate" );

            $(document).on( 'required:validate','#list_property_form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#list_property_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });
            
            $(document).on( 'click','#submit_list_my_property', function(e){
                var requiredFields = $('#list_property_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      

                var pushArray = [];

                var propertyTypeChecked = 0;

                $('input[name="property_type[]"]').each(function(){
                    if( $(this).is(":checked") ){
                        propertyTypeChecked = 1;
                    }
                });
                if( !propertyTypeChecked ){
                    pushArray.push("Property Type");
                }

                requiredObject.additional = pushArray;


                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                if( !$('#list_property_form').valid() || pushArray.length ){
                    e.preventDefault();
                }
                else{
                    
                }
            });
        };

        return {
            configureContact: configureContact,
            configureAgentContact: configureAgentContact,
            configureLogin: configureLogin,
            configureForget: configureForget,
            configureSignUp: configureSignUp,
            configureReset: configureReset,
            configureProfile: configureProfile,
            configureValuation: configureValuation,
            configureFindProperty: configureFindProperty,
            configureListProperty: configureListProperty,
        };
    };
})();
