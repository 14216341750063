var Property = ( function () {
    return function(){
    	var done = false;
        var player = undefined;
    	var delay = (function(){
			var timer = 0;
			return function(callback, ms){
				clearTimeout (timer);
				timer = setTimeout(callback, ms);
			};
		})();
		
		var propertyDetails = function(){

			$('[data-toggle="tooltip"]').tooltip(); 

			localStorage.setItem('previousListing', JSON.stringify($('#acronym').val()));

			applicationGoogle = Google();
			applicationGoogle.initialize();

			img_loop();
			mortgageCalculatorPopUp();
			mortgageForm();

			configurePropertyEnquiry();
			configureMortgage();
			propertyPopUp();
			configureShare();

			mortgageCalculator();

		    (function ($) {
		        function doAnimations(elems) {
		            var animEndEv = 'webkitAnimationEnd animationend';
		            elems.each(function () {
		                var $this = $(this),
		                    $animationType = $this.data('animation');
		                $this.addClass($animationType).one(animEndEv, function () {
		                    $this.removeClass($animationType);
		                });
		            });
		        }		        
		        var $myCarousel = $('#carousel-example-generic')

		        var $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
		        //Initialize carousel
		        $myCarousel.carousel();

		        //Animate captions in first slide on page load
		        doAnimations($firstAnimatingElems);
		        //Pause carousel
		        $myCarousel.carousel('pause');
		        //Other slides to be animated on carousel slide event
		        $myCarousel.on('slide.bs.carousel', function (e) {
		            var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
		            doAnimations($animatingElems);
		        });
		        $('#carousel-example-generic').carousel({
		            interval: 3000,
		            pause: "false"
		        });
		    })(jQuery);

		    $(document).on( 'click','#toggle_map', function(e){
		    	e.preventDefault();

		    	if($(this).data('location') == 1){
		    		$('.map2').hide();
		    		$('#slider_container').show();
		    		$(this).data('location', 0);
					$(this).html('<i class="fa fa-map-marker"></i>');
					$('#image-container').show();

				}
				else{
					$('#slider_container').hide();
					$('.map2').show();
					$(this).data('location', 1);
					$(this).html('<i class="fa fa-image"></i>');
				}
				$('#property_mortgage_div').hide();
		    });


		    $(document).on( 'click','#display_mortgage_calculator', function(e){
		    	$('#property_mortgage_div').show();
		    	$('#image-container').hide();
		    	$('#image-container2').hide();
		    	$('.rent-sale-acronym-blip').hide();

		    	$('.details-rent-sale-blip').hide();
		    	$('.details-status-blip').hide();
            });
		    
		    $(document).on( 'click','#close_mortgage_modal', function(e){
		    	$('#property_mortgage_div').hide();
		    	$('#image-container').show();
		    	$('#image-container2').show();
		    	$('.rent-sale-acronym-blip').show();

		    	$('.details-rent-sale-blip').show();
		    	$('.details-status-blip').show();
            });

            $(document).on( 'click','#twitter_share', function(e){
            	e.preventDefault();
		    	socialShare({ share: 'twitter', title: $('#page_title').val(), url: $('#page_url').val() })
            }); 

		};

		var mortgageCalculatorPopUp = function(){
			var mortgageCall 		= $('.mortgageCalculator > a');
			var mortgageBlock 		= $('#nms_calculator_pop');
			var mortgageMail 		= $('#mortgageFormBlock');

			mortgageCall.click(function(e){
				e.preventDefault();
				if(mortgageCall.data('hide') == 1){
					mortgageBlock.show();
					mortgageCall.data('hide', 0);
					$('.mortgageCalculator').addClass('open');
				}else{
					mortgageBlock.hide();
					mortgageCall.data('hide', 1);
					$('.mortgageCalculator').removeClass('open');
				}
			});	
		}

		var propertyPopUp = function(){
			var requestCall = $('.request_information');
			var actionDiv = $('#contact_action_div');
			var requestClose = $('.close-contact_action_div');


			requestCall.click(function(e){
				e.preventDefault();
				if($(this).data('hide') == 1){
					actionDiv.show();
					$(this).data('hide', 0);
				}else{
					actionDiv.hide();
					$(this).data('hide', 1);	
				}
				PropertyDetaisSlder();
			});

			requestClose.click(function(e){
				e.preventDefault();
				if($('.request_information').data('hide') == 1){
					actionDiv.show();
					$('.request_information').data('hide', 0);
				}else{
					actionDiv.hide();
					$('.request_information').data('hide', 1);	
				}
			});
		};

		var linkRealSliders = function(bigS,thumbS){
			$(".carousel li a").on("click", function(e){
				e.preventDefault();
				var newIndex = $(this).attr("data-slide-index");
				bigS.goToSlide(newIndex);
			});
		};

		var PropertyDetaisSlder = function(){
			var max_screen_width = 630;

			if($('.property_details_slider').length){
				delay(function(){
					var width 	= $(window).width();
					var height 	= $(window).height();

					if(parseInt(width) > max_screen_width){
						detailsSlider.reloadSlider({
							pagerCustom: '#carousel',
							speed:1000,
							pager:false,
							controls : true,
							nextText:'Next',
							prevText:'Previous',

							nextSelector: '#slide-next',
							prevSelector: '#slide-prev',
							nextText: '<i class="fa fa-caret-right" aria-hidden="true"></i>',
							prevText: '<i class="fa fa-caret-left" aria-hidden="true"></i> ',

							infiniteLoop: false,
							hideControlOnEnd: false,
							onSlideAfter: function(){
								    // do funky JS stuff here
								    var slideQty = detailsSlider.getSlideCount();
								    var currentSlide = detailsSlider.getCurrentSlide();
								    
								    $('#current_val').text(currentSlide+1);
								    $('#total_val').text(slideQty);
							},
							onSlideBefore: function($slideElement, oldIndex, newIndex){
								//changeRealThumb(realThumbSlider,newIndex);
							}
						});

						var slideQty = detailsSlider.getSlideCount();
						var currentSlide = detailsSlider.getCurrentSlide();

						$('#current_val').text(currentSlide+1);
						$('#total_val').text(slideQty);

						var itemCnt = 2; // this will be the number of columns per row
						var iWidth = parseFloat($('.sideCarousel').width() / itemCnt) -8;


						carouselSlider.reloadSlider({
							minSlides: 2,
							maxSlides: 2,
							slideWidth: 200,
							slideMargin: 0,
							pager:false,
							speed:1000,
							infiniteLoop : false,
							auto: false,
							controls: ($(".carousel li").length > 2) ? true: false,
							hideControlOnEnd: false,
							nextSelector: '#nextSlide',
							prevSelector: '#prevSlide',
							nextText: 'Next <i class="fa fa-angle-right" aria-hidden="true"></i>',
							prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i> Previous ',
							onSlideBefore:function($slideElement, oldIndex, newIndex){

							},
							onSliderLoad: function(){
								//$('.carousel li').css('width', iWidth+'px');	
							}
						});
						linkRealSliders(detailsSlider,carouselSlider);

					}else{
						detailsSlider.reloadSlider({	
							pagerCustom: '#carousel',
							speed:1000,
							pager:false,
							controls : true,
							nextText:'Next',
							prevText:'Previous',
							infiniteLoop: false,
							hideControlOnEnd: false,
							onSlideAfter: function(){
								    // do funky JS stuff here
								    var slideQty = detailsSlider.getSlideCount();
								    var currentSlide = detailsSlider.getCurrentSlide();
								    
								    $('#current_val').text(currentSlide+1);
								    $('#total_val').text(slideQty);
								},
								onSlideBefore: function($slideElement, oldIndex, newIndex){
									//changeRealThumb(realThumbSlider,newIndex);
								}
							});	
						var slideQty = detailsSlider.getSlideCount();
						var currentSlide = detailsSlider.getCurrentSlide();

						$('#current_val').text(currentSlide+1);
						$('#total_val').text(slideQty);

						var itemCnt = 2; // this will be the number of columns per row
						var iWidth = parseFloat($('.sideCarousel').width() / itemCnt)-8;

						carouselSlider.reloadSlider({
							minSlides: 2,
							maxSlides: 2,
							slideWidth: 200,
							slideMargin: 0,
							pager:false,
							speed:1000,
							infiniteLoop : false,
							auto: false,
							controls: ($(".carousel li").length > 2) ? true: false,
							hideControlOnEnd: true,
							nextSelector: '#nextSlide',
							prevSelector: '#prevSlide',
							nextText: 'Next <i class="fa fa-angle-right" aria-hidden="true"></i>',
							prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i> Previous ',
							onSlideBefore:function($slideElement, oldIndex, newIndex){

							},
							onSliderLoad: function(){
								//$('.carousel li').css('width', iWidth+'px');	
							}
						});

						linkRealSliders(detailsSlider,carouselSlider);
					}
				}, 200);
			}
		};

		var img_loop = function() {	
			var property_list_string = '';
			var propertyArray = [];
			
		    $('.property_details_slider li').each(function() {
				propertyArray.push({
		      		href: $(this).data('img-src')
		   		});        
		    });
		    if (propertyArray.length === 0) {

			}else{
				$("#photo_link, #photo_link2").click(function(e) {
					e.preventDefault();
					
					$.fancybox.open( propertyArray , {
						helpers : {
							thumbs : {
								width: 75,
								height: 50
							},
							overlay : {
								css : {
									'background' : 'rgba(0, 0, 0, 0.95)'
								}
							}		
						},
						type: "image",
						beforeShow: function () {
							var newWidth = this.width * 2;
							var newHeight = this.height * 2;
							
							$(".fancybox-image").css({
								"width": newWidth,
								"height": newHeight
							});
							this.width = newWidth;
							this.height = newHeight;
						}
					});
				});	
			}
		}

		var descriptionText = function(){			
			if($('#prop-description-text')){
			    $('#prop-description-text').readmore({
			      moreLink: '<a href="#">Read More</a>',
			      lessLink: '<a href="#">Read Less</a>',
			      collapsedHeight: 124,
			      heightMargin: 0
			    });
			}
		};

		var agentProfileText = function(){
			if($('#agent-profile').length){
				$('#agent-profile').readmore({
			      moreLink: '<a href="#">Read More</a>',
			      lessLink: '<a href="#">Read Less</a>',
			      collapsedHeight: 50
			    });
			}
		};

		configureChart = function(object){
			if($('#price_comparison_chart').length){
				new Chart(document.getElementById("price_comparison_chart"), object);
			}
		};

		propertySearch = function(object){
			var  utilities = Utilities();

            var applicationForm = $('#contact_form');
            var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            var notificationDiv = $('#notification_div');
            var submitButton = $('#contact_agent');

            applicationForm.trigger( "required:validate" );

            if(localStorage.getItem('previousListing') && localStorage.getItem('previousListing') !== "undefined" && localStorage.getItem('previousListing') !== undefined ){
            	
				utilities.propertyScroll(localStorage.getItem('previousListing'));
			}

            $(document).on( 'required:validate','#contact_form', function(){
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#contact_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#contact_agent', function(e){   
            	var requiredFields = $('#contact_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                

                if( !$('#contact_form').valid() ){
            		e.preventDefault();
            	}
            	else{

            	}
            });

            $(document).on( 'change','.amenity_check', function(e){   
            	$('#search_limit').val($('#limit_filter').val());
		    	$('#search_range').val($('#range_filter').val());
            	$('#internal_main_search').submit();
            });

            $(document).on( 'change','.status_check', function(e){  
            	$('#search_limit').val($('#limit_filter').val());
		    	$('#search_range').val($('#range_filter').val()); 
            	$('#internal_main_search').submit();
            });
		};

		mortgageCalculator = function(){
			var downPayment = $('#down_payment');
			var termsYears = $('#terms_years');	

			var monthlySpan = $('.mortgage_monthly_span');
			var monthlyPayment = $('.monthly_payment');	
			var loanSpan = $('.mortgage_loan_span');	
			var mortgage_period = $('.mortgage_period');
			var homePrice = $('#home_price');

			mortgage_period.html(termsYears.val()+" Yrs");

			downPayment.keyup(function(){
				mortgageCalculation();
				$('.mortgage_period').html(termsYears.val()+" Yrs");
			});

			termsYears.keyup(function(){
				mortgageCalculation();
				$('.mortgage_period').html(termsYears.val()+" Yrs");
			});
		};

		mortgageCalculation = function(){
			var utilities = Utilities();
			var mortgageRateHidden = $('input[name="mortgage_calculator_rate[]"]');

			var homePrice = $('#home_price');
			var downPayment = $('#down_payment');
			var termsYears = $('#terms_years');	
			var monthlySpan = $('.mortgage_monthly_span');
			var monthlyPayment = $('.monthly_payment');	
			var loanSpan = $('.mortgage_loan_span');

			mortgageRateHidden.each(function(){
				var principle;
				var price = homePrice.val();
				var downInterestPayment = downPayment.val();

				var interestRate  = $(this).val();
				var yearlyInterestRate;
				var months;
				var dataCompany = $(this).data('company');
				var loanAmount = "";

				principle = price - downInterestPayment;
				yearlyInterestRate = (interestRate/100) / 12;
				months = parseInt(termsYears.val()) * 12;

				loanAmount = parseInt(price) - parseInt(downInterestPayment);		

				utilities.formatCurrency(Math.floor((principle*yearlyInterestRate)/(1-Math.pow(1+yearlyInterestRate,(-1*months)))*100)/100,monthlySpan.filter('[data-company="'+dataCompany+'"]'));
				utilities.formatCurrency(Math.floor((principle*yearlyInterestRate)/(1-Math.pow(1+yearlyInterestRate,(-1*months)))*100)/100,monthlyPayment.filter('[data-company="'+dataCompany+'"]'))
				utilities.formatCurrency(loanAmount,loanSpan.filter('[data-company="'+dataCompany+'"]'));
			});
		};

		mortgageForm = function(){
			var mortgageChoice = $('input[name="mortgageChoice"]');
			var mortgageMail = $('#mortgageFormBlock');
			$(document).on('change', 'input[name="mortgageChoice"]', function(e) {
			
				dataCompany 				= $(this).data('company');
				mortgageRateHidden 			= $('input[name="mortgage_calculator_rate[]"]');
				monthlySpan 				= $('.mortgage_monthly_span');
				loanSpan 					= $('.mortgage_loan_span');	
				mortgage_period 			= $('.mortgage_period');

				$('#preferred_mortgagee').val($(this).data('company'));
				$('#preferred_monthly_payment').val($('.mortTable .mortgage_monthly_span[data-company="'+dataCompany+'"]').text());
				$('#preferred_rate').val($('.mortTable .mortgagerate[data-company="'+dataCompany+'"]').val());

				if($('input[name=mortgageChoice]:checked')){
					mortgageMail.show();	
					mortgageMail.data('hide', 0);   	
				}else{
					mortgageMail.hide();	
					mortgageMail.data('hide', 1);		
				}
				
			});

			$(document).on('click', '.cancel-mortgage-form', function(e) {
				e.preventDefault();
				mortgageMail.hide();
				mortgageMail.data('hide', 1);
				$('input[name=mortgageChoice]').attr('checked', false);
			});
		};

		configurePrint = function(){
			var mapStatus = 0;
			var spamFilter2 = null,
			spamFilter3 = null;
	
			var slider = $('.report_property_slider').bxSlider({
				pager:false,
				auto: ($(".report_property_slider li").length > 1) ? false: false ,
				controls: ($(".report_property_slider li").length > 1) ? true: false,
				speed: 1590,
				pause: 10000,
				preloadImages : 'all',
				useCSS: false,
				easing: 'linear',
				onSlideAfter: function(){
				  var slideQty = slider.getSlideCount();
				  var currentSlide = slider.getCurrentSlide();
				
				  $('#current_val').text(currentSlide+1);
				  $('#total_val').text(slideQty);
				}	            
			});
			var slideQty = slider.getSlideCount();
			var currentSlide = slider.getCurrentSlide();
	
			 $('#current_val').text(currentSlide+1);
			 $('#total_val').text(slideQty);
	
			 $('.bx-prev , .bx-next, .bx-pager-item').on('click',function(e){
			 	e.preventDefault();
			 	slider.stopAuto();
			  	slider.startAuto();            
			 });

			$('#print_page').click(function(){
				window.print();
			});
	
			locationLink 		= $('#location_link');
			locationSpan		= $('#location_span');
			imageDiv 				= $('#image_div');
			mapDiv 					= $('#map');	
			lattitudeField 	= $('input[name="lattitude"]');
			longitudeField 	= $('input[name="longitude"]');
			Zoom						= 14;
	
			locationLink.click(function(e){
				e.preventDefault();

				if(locationLink.data('hide') === 0){
					mapDiv.show();
					if(mapStatus === 0){
						var applicationGoogle = Google();
		    			applicationGoogle.initialize();	
						mapStatus = 1;
					}
					imageDiv.hide();		
					locationSpan.html('Hide Map');
					locationLink.data('hide',1);
				}
				else if(locationLink.data('hide') === 1){
					locationLink.data('hide',0);
					mapDiv.hide();
					imageDiv.show();
					locationSpan.html('Map');						
					slider.reloadSlider();
				}
			});
	
			if($('#share_form').length > 0){
			
				spamFilter 			= $('#email_address_sent');
				spamFilter2 		= $('#email_address_sent2');
				spamFilter3 		= $('#email_address_sent3');
				
				shareForm = $('#share_form');
				shareValidator = shareForm.validate();
				shareForm.removeAttr("novalidate");	
				shareFirstName 	= $('#ShareForm_firstName');	
				shareLastName 	= $('#ShareForm_lastName');	
				shareEmail 		= $('#ShareForm_emailAddress');
				shareFriendFullName = $('#ShareForm_friendsName');
				shareFriendEmail = $('#ShareForm_friendsEmail');
				shareComment = $('#ShareForm_comment');
					/*shareFullName.rules("add",{
						required:true,
						 messages: {
					            required: "Please Enter Your Full Name"
					     }
					});*/
					
				shareEmail.rules("add",{
						required:true,
						 email:"required",
						 messages: {
					           email: "Please Enter A Valid Email Address",
					           required:"Please Enter Your Email Address"
					     }
					});
				shareFriendFullName.rules("add",{
						required:true,
						 messages: {
					            required: "Please Enter Your Friend's Full Name"
					     }
					});
				shareFriendEmail.rules("add",{
						required:true,
						 email:"required",
						 messages: {
					           email: "Please Enter A Valid Email Address",
					           required:"Please Enter Your Friend's Email Address"
					     }
					});
				shareComment.rules("add",{
						required:true,
						 messages: {
					            required: "Please Enter A Comment"
					     }
					});  
					
				$(document).on('click', '#share_submit', function(e) {
					if(shareForm.valid() && spamFilter2.val() === ""){				
						sendContact('Friend');
						e.preventDefault();
					}
				});
			}
			
			

			$('#main_greeting').html(getGreeting());
		};

		var getGreeting = function(){		
			var now = new Date();
			var hour = now.getHours();

			if( parseInt(hour.toString()) < 12){
		        return "Top of the morning to you";
		    }
		    else if( parseInt(hour.toString()) > 11 && parseInt(hour.toString()) < 17 ){
		        return "Good Afternoon";
		    }
		    else if( parseInt(hour.toString()) > 16 && parseInt(hour.toString()) < 19){
		        return "Good Evening";
		    }
		    else if( parseInt(hour.toString()) > 18 && parseInt(hour.toString()) < 24){
		        return "Good Night";
		    }
		}

		var configureMortgage = function(){
			var  utilities = Utilities();
			var applicationForm = $('#mortgage_form');

			var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

			var notificationDiv = $('#notification_mortgage_div');
			var submitButton = $('#submit_mortgage');

			applicationForm.trigger( "required:validate" );

			$(document).on( 'required:validate','#mortgage_form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#mortgage_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit_mortgage', function(e){          
                var requiredFields = $('#mortgage_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            	var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_mortgage_div'));
                if( !$('#mortgage_form').valid() ){
            		e.preventDefault();
            	}
            	else{

            	}
            });
		};

		var configurePropertyListGrid = function(){
			$('.btn-grid').on('click', grid);
	        $('.btn-list').on('click', list);
	        function list() {
	            $('.property-content').addClass('list-view');
	            $('.btn-grid').removeClass('active');
	            $('.btn-list').addClass('active');
	            $('.lst-item').removeClass('nodisplay');
	            $('.grd-item').addClass('nodisplay');
	            $('#loading').show();
	            setTimeout(function() { $('#loading').hide();}, 300);
	            $('.btn-list').off('click');
	            $('.btn-grid').on('click', grid);
	        };
	        function grid() {
	            $('.property-content').removeClass('list-view');
	            $('.btn-grid').addClass('active');
	            $('.btn-list').removeClass('active');
	            $('.lst-item').addClass('nodisplay');
	            $('.grd-item').removeClass('nodisplay');
	            $('#loading').show();
	            setTimeout(function() { $('#loading').hide();}, 300);
	            $('.btn-grid').off('click');
	            $('.btn-list').on('click', list);
	        };
		}

		var setPropertyListDisplay = function(){
			$('.property-content').addClass('list-view');
            $('.btn-grid').removeClass('active');
            $('.btn-list').addClass('active');
            $('.lst-item').removeClass('nodisplay');
            $('.grd-item').addClass('nodisplay');
            $('#loading').show();
            setTimeout(function() { $('#loading').hide();}, 300);
            //$('.btn-list').off('click');
            //$('.btn-grid').on('click', grid);

		}

		var setPropertyGridDisplay = function(){
			$('.property-content').removeClass('list-view');
            $('.btn-grid').addClass('active');
            $('.btn-list').removeClass('active');
            $('.lst-item').addClass('nodisplay');
            $('.grd-item').removeClass('nodisplay');
            $('#loading').show();
            setTimeout(function() { $('#loading').hide();}, 300);
            //$('.btn-grid').off('click');
            //$('.btn-list').on('click', list);

		}

		var configurePropertyEnquiry = function(){
			var  utilities = Utilities();

			var applicationForm = $('#enquiry_form');

			var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

			var notificationDiv = $('#notification_div');
			var submitButton = $('#submit_enquiry');

			applicationForm.trigger( "required:validate" );

			$(document).on( 'click','#submit_enquiry', function(e){          
                var requiredFields = $('#enquiry_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            	var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                if( !$('#enquiry_form').valid() ){
            		e.preventDefault();
            	}
            	else{

            		$('#submit_enquiry').attr("disabled","disabled");

            		var submissionUrl = {
            			submit_loading: 1
            		};

            		$('#submit_enquiry').addClass("nopadding");

            		$('#submit_enquiry').html(Handlebars.partials['partial_bars'](submissionUrl));

            		$('#enquiry_form').submit();
            	}
            });

            $(document).on( 'click','#submit_enquiry', function(e){          
                var requiredFields = $('#enquiry_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            	var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_div'));
                if( !$('#enquiry_form').valid() ){
            		e.preventDefault();
            	}
            	else{

            	}
            });

            var  utilities = Utilities();
			var applicationForm2 = $('#enquiry_form_large');

			var requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

			var notificationDiv2 = $('#notification_div2');
			var submitButton2 = $('#submit_contact_2');

			applicationForm2.trigger( "required:validate" );

			$(document).on( 'required:validate','#enquiry_form_large', function(){                
                requiredFields2 = $(applicationForm2.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#enquiry_form_large').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit_enquiry_2', function(e){          
                var requiredFields2 = $('#enquiry_form_large' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            	var requiredObject2 = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject2,requiredFields2,$('#notification_div2'));
                if( !$('#enquiry_form_large').valid() ){
            		e.preventDefault();
            		$('#send_enquiry_tip_container').hide();
            	}
            	else{
            		$('#submit_enquiry_2').attr("disabled","disabled");


            		var submissionUrl = {
            			submit_loading: 1
            		};

            		$('#submit_enquiry_2').addClass("nopadding");

            		$('#submit_enquiry_2').html(Handlebars.partials['partial_bars'](submissionUrl));

            		$('#enquiry_form_large').submit();
            	}
            });
		};

		var configurePropertyShowing = function(){
			var  utilities = Utilities();
			var applicationForm = $('#showing_form');

			var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

			var notificationDiv = $('#notification_showing_div');
			var submitButton = $('#submit_showing');

			applicationForm.trigger( "required:validate" );

			$(document).on( 'required:validate','#showing_form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#showing_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit_showing', function(e){          
                var requiredFields = $('#showing_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            	var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_showing_div'));
                if( !$('#showing_form').valid() ){
            		e.preventDefault();
            	}
            	else{

            	}
            });

            $('.date-time-picker').datetimepicker({
		        icons: {
		            time: "fa fa-clock-o",
		            date: "fa fa-calendar",
		            up: "fa fa-arrow-up",
		            down: "fa fa-arrow-down"
		        }
		    }).on("dp.change",function (e) {
               window['angularComponentRef'].component.setRequestDate(e.date.toISOString().slice(0,10));
            });
		};

		var configureShare = function(){
			var  utilities = Utilities();
			var applicationForm = $('#share_form');

			var requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");

			var notificationDiv = $('#notification_share_div');
			var submitButton = $('#submit_share');

			applicationForm.trigger( "required:validate" );

			$(document).on( 'required:validate','#share_form', function(){                
                requiredFields = $(applicationForm.selector +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
                $('#share_form').validate({
                    errorElement : 'div',
                    errorPlacement: function(error, element) {
                        var placement = $(element).data('error');
                        if (placement) {
                            $(placement).append(error)
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });

            $(document).on( 'click','#submit_share', function(e){          
                var requiredFields = $('#share_form' +' .required').filter("[type='text'], [type='email'], select, [type='password'], [type='checkbox'], [type='radio'], textarea");
            	var requiredObject = {message:"Please fill in required fields"};      
                utilities.requiredUlCheck(requiredObject,requiredFields,$('#notification_share_div'));
                if( !$('#share_form').valid() ){
            		e.preventDefault();
            	}
            	else{

            		var submissionUrl = {
            			submit_loading: 1
            		};

            		$('#submit_share').addClass("nopadding");

            		$('#submit_share').html(Handlebars.partials['partial_bars'](submissionUrl));

            		setTimeout(function () { $('#submit_share').attr("disabled","disabled"); }, 0);
            	}
            });
            
		};

		var propertyVideo = function(){
			callVideo();		
			$('#main_player').show();
		};
		var callVideo = function(){
			if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
				var tag = document.createElement('script');
				tag.src = "//www.youtube.com/iframe_api";
				var firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
			}
			var vStatus = $('input[name="video_status"]').val();
			if(vStatus == 'stopped' || vStatus == 'paused'){
				startVideo();
			}
		};

		window.onYouTubeIframeAPIReady = function(){
			var video_prop_id = $('input[name="video_id"]').val();
		    player = new YT.Player('player', {
		    	height: '390',
		    	width: '640',
		    	videoId: video_prop_id,
		    	events: {
		    		'onReady': onPlayerReady,
		    		'onStateChange': onPlayerStateChange
		    	}
		    });
		};

		var stopVideo = function(){
			if($('#video_id').length){
				$('#player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*'); 
				if(player !== undefined){
					player.stopVideo();
				}
				$('input[name="video_status"]').val('stopped');
			}
		};	

		var pauseVideo = function(){
			if($('#video_id').length){
				$('#player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*'); 
				if(player != undefined){
					player.pauseVideo();
				}
				$('input[name="video_status"]').val('paused');
			}
		};

		var startVideo = function(){
			if($('#video_id').length){
				$('#player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*'); 
				if(player != undefined){
					player.playVideo();
				}
				$('input[name="video_status"]').val('started');
			}
		};

		var onPlayerReady = function(event){
			event.target.playVideo();
		};

		var onPlayerStateChange = function(event){
			if (event.data == YT.PlayerState.PLAYING && !done) {
				//setTimeout(stopVideo, 6000);
        		done = true;
    		}
		};

		var socialShare = function(parameters){
			
			switch(parameters.share){
				case 'twitter':
                window.open('https://twitter.com/intent/tweet?text=' + parameters.title + ' ' + encodeURIComponent(parameters.url), 'sharertwt', 'toolbar=0,status=0,width=640,height=445');
                break;
                case 'pinterest':
				var img_url = parameters.image;
				var sharingUrl = parameters.url;
                window.open('https://www.pinterest.com/pin/create/button/?media=' + img_url + '&url=' + sharingUrl , 'sharerpinterest', 'toolbar=0,status=0,width=660,height=445');
                break;
            }
		};

        return {
            propertyDetails: propertyDetails,
            descriptionText: descriptionText,
            configureChart: configureChart,
            propertySearch: propertySearch,
            mortgageCalculator: mortgageCalculator,
            mortgageForm: mortgageForm,
            agentProfileText: agentProfileText,
            configurePrint: configurePrint,
            propertyVideo: propertyVideo,
            stopVideo: stopVideo,
            pauseVideo: pauseVideo,
            startVideo: startVideo,
            configurePropertyListGrid: configurePropertyListGrid,
            setPropertyListDisplay: setPropertyListDisplay,
            setPropertyGridDisplay: setPropertyGridDisplay,
            getGreeting: getGreeting,
            socialShare: socialShare,

        };
    };
})();
