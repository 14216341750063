var Google = ( function () {
    return function(){

        var youtubePlayer = null;

		var configureLogin = function(){
            var googleAppId = $('input[name="google_app_id"]').val();
            (function() {
                var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
                po.src = 'https://apis.google.com/js/client:plusone.js';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
            })();

            $(document).on( 'click','#google_in, #google_in_details', function(e){
                e.preventDefault();
                gapi.auth.signIn(
                    {
                        'clientid' : '831113217250-qf9472rb0tqies3ua3e5tr245t2km2ni.apps.googleusercontent.com',
                        'cookiepolicy' : 'single_host_origin',
                        'callback' : 'googlePlusSignInCallBack',
                        'scope': 'https://www.googleapis.com/auth/plus.login email'
                    }
                );
            });


		};

        var setYoutubePlayer = function(value){
            youtubePlayer = value;
        };
        var getYoutubePlayer = function(){
            return youtubePlayer;
        };

		var initialize = function(){
			var jamaica = new google.maps.LatLng(18.081112, -77.426147);
			var  latitudeArray = $('input[name="latitude[]"]');
			var  longitudeArray =  $('input[name="longitude[]"]');
			var locationArray = new Array();
			var zoom = null;
			var listField = $('input[name="locations[]"]');

			if(listField !== null){
				listField.each(function(){
					locationArray.push($(this).val());
				});
			}            

			if((longitudeArray.length === 1 && latitudeArray.length === 1 ) ){
				jamaica = new google.maps.LatLng(latitudeArray.val(), longitudeArray.val());	
				if(zoom == null){
					zoom = 18;
				}
			}else{
				zoom = 8;
			}

			var myOptions = {
				zoom: zoom,
				center: jamaica,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}

			map = new google.maps.Map(document.getElementById("map"), myOptions);

			for (var i = 0; i < longitudeArray.length; i++) {		
				var marker = new google.maps.Marker({
					position: new google.maps.LatLng(latitudeArray.eq(i).val(),longitudeArray.eq(i).val()),
					map: map,
					title: 'Marker',
                    //icon: 'assets/images/map-icon.png'
				});
				if(listField !== null){
					var infoWindow = new google.maps.InfoWindow(), marker, i;
					google.maps.event.addListener(marker, 'click', (function(marker, i) {
						return function() {
							infoWindow.setContent(locationArray[i]);
							infoWindow.open(map, marker);
						}
					})(marker, i));
				}
			}
            
            window.dispatchEvent(new Event('resize'));

            google.maps.event.trigger(map, 'resize');

		}
        return {
            init: function(){
                try{
                }
                catch(e){
                    console.error(e);
                }
            },
            configureLogin: function(){
            	configureLogin();
            },
            initialize: initialize,
            setYoutubePlayer: setYoutubePlayer,
            getYoutubePlayer: getYoutubePlayer,
        };
    }
})();
var googlePlusSignInCallBack = function(authResult){
    var utilities = Utilities();
    if (authResult['status']['signed_in']) {
        $.ajax({
            type: "GET",
            url: "https://www.googleapis.com/oauth2/v2/userinfo?access_token="+authResult['access_token']
        }).done(function( data ){
            var apiObject = {};
            apiObject.first_name = data.given_name;
            apiObject.id = data.id;
            apiObject.profile = data.link;
            apiObject.last_name = data.family_name;
            apiObject.email = data.email;
            apiObject.image = data.picture;
            apiObject.sent_api = 'Google +';
            utilities.apiLogin(apiObject);
        });
    }
};
