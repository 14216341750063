var LinkedIn = ( function () {
    return function(){
		var configureLogin = function(){
            var utilities = Utilities();
            $(document).on( 'click','#linked_in, #linkedin_details', function(e){
                e.preventDefault();
                IN.User.authorize(function() {
                    IN.API.Profile("me").fields("first-name", "last-name", "email-address","picture-url").result(function(result) {
                        if(result.values.length){
                            var apiObject = {};
                            apiObject.first_name = result.values[0].firstName;
                            apiObject.last_name = result.values[0].lastName;
                            apiObject.email = result.values[0].emailAddress;
                            apiObject.image = result.values[0].pictureUrl;
                            apiObject.sent_api = 'LinkedIn';
                            utilities.apiLogin(apiObject);
                        }
                    });
                });
            });
		};
        return {
            init: function(){
                try{
                }
                catch(e){
                    console.error(e);
                }
            },
            configureLogin: function(){
            	configureLogin();
            }
        };
    }
})();