var Facebook = ( function () {
    return function(){
		var configureLogin = function(){
            var faceBookAppId = $('input[name="facebook_app_id"]').val();
            window.fbAsyncInit = function() {
                FB.init({
                    appId      : faceBookAppId,
                    xfbml      : true,
                    version    : 'v2.6'
                });
            };
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
           }(document, 'script', 'facebook-jssdk'));

            $(document).on( 'click','#facebook_in , #facebook_in_details', function(e){
                FB.init({
                    appId      : faceBookAppId,
                    xfbml      : true,
                    version    : 'v2.6'
                });
                e.preventDefault();
                FB.login(function(response) {
                    statusChangeCallback(response);
                },{scope: 'email,user_photos'});
            });

		};
        var statusChangeCallback = function(response){
            if (response.status === 'connected') {      
                facebookAPILogin();
            } 
        };
        var facebookAPILogin = function(){
            var utilities = Utilities();
            FB.api('/me', {fields: 'id,first_name,last_name,email'},function(response) {
                if(!utilities.isEmptyObject(response)){
                    response.sent_api = "facebook"; 
                    var faceBookObject = response;
                    response.image = 'http://graph.facebook.com/'+response.id+'/picture?type=large';
                    localStorage.setItem('apiMember', JSON.stringify(faceBookObject));
                    utilities.apiLogin(response);
                }               
            });
        };
        return {
            init: function(){
                try{
                }
                catch(e){
                    console.error(e);
                }
            },
            configureLogin: function(){
            	configureLogin();
            }
        };
    }
})();