var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

/*
window.onYouTubeIframeAPIReady = function(){
	var video_prop_id	= $('input[name="video_id"]').val();
	var video_list_id	= $('input[name="video_list"]').val();
	var video_height 	= $('input[name="video_height"]');
	var video_width 	= $('input[name="video_width"]');

	if($('input[name="video_list"]').length > 0){
		player = new YT.Player('player', {
			height: (video_height.length > 0) ? video_height.val() : '462',
			width: (video_width.length > 0) ? video_width.val() : '640',
			videoId: video_prop_id,
			playerVars: { 
				listType:'playlist',
	          	list: video_list_id
			},
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
		});
	}
	else{
		player = new YT.Player('player', {
			height: (video_height.length > 0) ? video_height.val() : '462',
			width: (video_width.length > 0) ? video_width.val() : '640',
			videoId: video_prop_id,
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
		});
	}
};
function onPlayerReady(event) {
	var video_auto_play = $('input[name="video_auto_play"]');
	if(video_auto_play.val() === 'no'){
		
	}else{
		event.target.playVideo();
	}
}
var done = false;
function onPlayerStateChange(event) {
	if (event.data == YT.PlayerState.PLAYING && !done) {
		//setTimeout(stopVideo, 6000);
		done = true;
	}
}



*/

function slideFade(elem) {
   var fade = { opacity: 0, transition: 'opacity 0.5s' };
   elem.css(fade).slideUp();
}

function onPlayerReady(event) {
	var video_auto_play = $('input[name="video_auto_play"]');
	if(video_auto_play.val() === 'no'){
		
	}else{
		event.target.playVideo();
	}
}

function onPlayerStateChange(event) {
	if (event.data == YT.PlayerState.PLAYING && !done) {
		//setTimeout(stopVideo, 6000);
		done = true;
	}
}


var applicationGoogle = Google();


var Utilities = ( function () {
    return function(){
    	var previewFile = null;
    	var modalConfirm = null;
    	var loadModal = $('#loading_modal');
        var meterSpan = $(".meter > span");
        var done = false;
        var player = undefined;

		var requiredUlCheck = function(requiredObject,requiredFields,notificationDiv){
			var requiredHtml = {required_ul:1,message:requiredObject.message};
			var listItems = [];
			var requiredLabels = [];
			requiredFields.each(function(){
				if( requiredLabels.indexOf($(this).data('label')) === -1 && ( $(this).val() === "" || ($(this).hasClass("error") && !$(this).is("select") ) ) ){
					listItems.push({item:$(this).data('label')});
					$(this).addClass('error');

					requiredLabels.push( $(this).data('label') );
				}
			});

			console.log(listItems);

			if(requiredObject.additional !== undefined && requiredObject.additional !== null && requiredObject.additional.length){
				for (key in requiredObject.additional) {
					listItems.push({item:requiredObject.additional[key]});
				}
			}
			if(listItems.length){
				if(requiredObject.duplicate_hidden)
					requiredObject.duplicate_hidden.val("1");
				requiredHtml.list = listItems;
				notificationDiv.html(Handlebars.partials['partial_bars'](requiredHtml));
			}
			else{
				if(requiredObject.duplicate_hidden)
					requiredObject.duplicate_hidden.val("0");
				notificationDiv.html('');
			}
		};
        var JqueryValidateAdditionals = function (){
        	$.validator.addMethod("notEqual", function(value, element, param) {
        		return this.optional(element) || value != $(param).val();
        	}, "This has to be different...");
        	$.validator.addMethod('minimumValue', function (value, el, param) {
                return value > param;
            });
            $.validator.addMethod('maximumValue', function (value, el, param) {
                return value <= param;
            });
        };        	
		var ministring = function(str,maxLength){
			str = str.text();
    		var strLength = str.length;
    		var shortenedStr = str.substr(0, maxLength);
    		var shortenedStrLength = shortenedStr.length;
    		var lastSpace = str.lastIndexOf(" ");
    		if(shortenedStrLength != strLength){
    			var strDiff = strLength - shortenedStrLength;
       			var lastSpaceDiff = shortenedStrLength - lastSpace;
       			if(strDiff > lastSpaceDiff){
        			shortenedStr = str.substr(0, lastSpace);
       			}
       			shortenedStr += "...";
    		}
    		return shortenedStr;
		};		
		var loadingModal = function(show){
			if(show){
				loadModal.modal('show');
				/*
				meterSpan.each(function() {
				
				$(this)
					.data("origWidth", $(this).width())
					.width(0)
					.animate({
						width: $(this).data("origWidth")
					}, 1200);
				});
				*/
			}
			else{
				loadModal.modal('hide');
			}
		};		
		var isEmptyObject = function(obj){
			var name;
			for (name in obj) {
				return false;
			}
			return true;
		};
		var renderPage = function(){
			switch(cPage){
				case 'index':
				/*
				applicationQuote = Quote();
				applicationQuote.configureQuick();
				*/
				break;		
				case 'property_search':		
				applicationProperty = Property();
				applicationProperty.propertySearch();
				break;
				case'contact_us':
				applicationContact = Contact();
				applicationContact.configureContact();
				break;
				case 'print_property':		
				applicationProperty = Property();
				applicationProperty.configurePrint();
				break;

				case 'property_details':		
				applicationProperty = Property();
				applicationProperty.propertyDetails();
				break;
			}			
		};	

		var addBodyClass = function(classValue){
			if(classValue === undefined){
				if (!$('body').hasClass("internal")) {
					$('body').addClass('internal');
				}
			}
			else{
				if (!$('body').hasClass(classValue)) {
					$('body').addClass(classValue);
				}
			}
		}	
		var removeBodyClass = function(classValue){
			if(classValue === undefined){
				$('body').removeClass('internal');
			}
			else{
				$('body').removeClass(classValue);	
			}
		}

		var setAjaxRequest = function(value){
            ajaxRequest = value;
        };
        var getAjaxRequestPull = function(){
            return ajaxRequest;
        };  

        var getAjaxRequest = function(){
            return ajaxRequest;
        };  

		var ajaxDuplicateCheck = function(dataType,requestType,urlLocation,serializedData,duplicateSpan,hiddenElement,elementDiv,toggleLoadingDiv,toggleAuthenticate){
			$('#' + duplicateSpan).remove();
			if(toggleLoadingDiv)
				loadingModal(true);
			utilities.setAjaxRequest($.ajax({
				type:requestType,
				url: urlLocation,
				dataType: dataType,
				data: serializedData,
				})
			);
			utilities.getAjaxRequest().then(
				function(data){
					if(toggleLoadingDiv)
						loadingModal(false);
					if(toggleAuthenticate)
						authenticateAjaxRequest(data);

					if(parseInt(data.status) === 200 || parseInt(data.status) === 201 ){
						hiddenElement.val('0');
						elementDiv.hide();
						if(data.show !== undefined && parseInt(data.show) ){
							data.duplicate_span = 1;
							data.span_id = duplicateSpan;
							data.colour = "alert alert-danger alert-block";
							elementDiv.html(Handlebars.partials['partial_bars'](data));
							elementDiv.show();
						}
					}
					else{
						data.duplicate_span = true;
						data.span_id = duplicateSpan;
						data.colour = "alert alert-danger alert-block";
						elementDiv.html(Handlebars.partials['partial_bars'](data));
						hiddenElement.val('1');
						elementDiv.show();
					}
				},
				function(data){
					if(toggleLoadingDiv)
						loadingModal(false);
					if(toggleAuthenticate)
						authenticateAjaxRequest(data);
					hiddenElement.val('1');
				}
			);
		};
        var setModalConfirm = function(value){
        	modalConfirm = value;
		};
		var getModalConfirm = function(){
        	return modalConfirm;
		};
		var resetElements = function(elements){			
			if(elements.length){
				for (key in elements) {
					if($( elements[key].selector).length === 1)
						$( elements[key].selector).val('')
					else{
						$(elements[key]).each(function(){
							$(this).val('');	                        
						});
					}
				}
			}
		};
		var errorFocus = function(container){
    		$('html, body').animate({ scrollTop: $(container).offset().top }, 'slow');
		};	
		var displayContainer = function(container){
    		document.getElementById(container).removeAttribute("hidden");
    		$('#'+container).show();
		};
		var closeModal = function(container){
			$(container).modal('hide');    		
		};

		var configureInstagram = function(params){
			var feed = new Instafeed({
	    		get: "user",
	    		limit: params.limit,
	    		userId: params.user_id,
	    		accessToken: params.access_token,
	    		template: '<a href="{{link}}" target="_blank"><img src="{{image}}" /></a>'
			});
    		feed.run();
		};		

		var stickHead = function(params,callBack){
		    $('#top-navigation-section').affix({
			    offset: { top: 0 }
			});
		}

		var checkMedia = function(params,callBack){
			var tolerancePixel = 40;
			var scrollTop = $(window).scrollTop() + tolerancePixel;
		    var scrollBottom = $(window).scrollTop() + $(window).height() - tolerancePixel;
		    var videoStatus = $('#video_status').val();

		    if($('.video_block').length > 0){
			    $('.video_block').each(function(index, el) {
			        var yTopMedia = $(this).offset().top;
			        var yBottomMedia = $(this).height() + yTopMedia;

			        if(scrollTop < yBottomMedia && scrollBottom > yTopMedia){
			        	if(videoStatus == 'start'){
			            	startVideo();
			            	applicationGoogle.getYoutubePlayer().playVideo();
			            }
			        } else {
			           	applicationGoogle.getYoutubePlayer().pauseVideo();
			        }
			    });
			}
		};

		var getYoutubeList = function(params,callBack){
			
			if(params.video_type != 'app-videos'){
				$.get('https://www.googleapis.com/youtube/v3/channels', {
					part: 'contentDetails',
					//forUsername: params.channel_name,
					id: params.channel_id,
					key: params.api_key
				},function(data){
					$.each(data.items, function(i, item){
						pid = item.contentDetails.relatedPlaylists.uploads;
						params.pid = pid;
						getVids(params,function(err,response){
							
						});
					});				
				});
			}else{
				callVideo(null,'single-video-2');	
			}
		};

		var getVids = function(params,callBack){
			$.get('https://www.googleapis.com/youtube/v3/playlistItems', {
				part: 'snippet',
				maxResults: 5,
				playlistId: params.pid,
				key: params.api_key
			},function(data){
				
				var output;
				shuffle(data.items);
				$.each(data.items, function(i, item){						
					callVideo(item,'single_video');
				});				
			});
		};

		window.onscroll = function() {
			checkMedia();
		};

		var shuffle = function(array){
			var currentIndex = array.length, temporaryValue, randomIndex;

			while (0 !== currentIndex) {
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex -= 1;

				temporaryValue = array[currentIndex];
				array[currentIndex] = array[randomIndex];
	      		array[randomIndex] = temporaryValue;
			}
			return array;
		};

		var callVideo = function(item,type){
			switch(type){
				case 'playlist':
					/*videoTitle = item.snippet.title;
					videoId = item.snippet.resourceId.videoId;
					videDescription = item.snippet.description;*/

					$('input[name="video_list"]').val(item.snippet.channelId);
					/*$('.vided_home_container .video_title').html(videoTitle);
					$('.vided_home_container .video_details').html(videDescription);*/

				break;
				case 'single_video':
					videoTitle = item.snippet.title;
					videoId = item.snippet.resourceId.videoId;
					videDescription = item.snippet.description;

					$('input[name="video_id"]').val(videoId);
					$('.vided_home_container .video_title').html(videoTitle);
					$('.vided_home_container .video_details').html(videDescription);
				break;
				case 'single-video-2':
					var video_prop_id	= $('#video_id').val();
					var video_height 	= $('#video_height');
					var video_width 	= $('#video_height');					

					player = new YT.Player('player', {
						height: (video_height.length > 0) ? video_height.val() : '462',
						width: (video_width.length > 0) ? video_width.val() : '640',
						videoId: video_prop_id,
						events: {
							'onReady': onPlayerReady,
							'onStateChange': onPlayerStateChange
						}
					});


					applicationGoogle.setYoutubePlayer(player);


				break;
			}
			// 2. This code loads the IFrame Player API code asynchronously.
			if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
				var tag = document.createElement('script');
				tag.src = "//www.youtube.com/iframe_api";
				var firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				
			}
			else{
			}
		};		

		var onPlayerReady = function(event){
			var video_auto_play = $('input[name="video_auto_play"]');
			var video_list_id	= $('input[name="video_list"]').val();
			var video_status_name = $('input[name="video_status"]').val();

			if( applicationGoogle.getYoutubePlayer() != undefined){
				if(video_auto_play.val() === 'no'){

				}else{
					playlistLength = 10;
					
					applicationGoogle.getYoutubePlayer().cuePlaylist({
						'listType': 'playlist',
						'list': video_list_id,
						'index' : [Math.floor(Math.random() * playlistLength)]
					});

					setTimeout( function() { 
				          //event.target.stopVideo();
				          //event.target.playVideoAt(3);
				          event.target.setShuffle(true); 
				          event.target.playVideo();
				          event.target.setLoop(true);
				    }, 
				    1000);
				}
			}
		};

		var onPlayerStateChange = function(event){
			if (event.data == YT.PlayerState.PLAYING && !done) {
				//setTimeout(stopVideo, 6000);
				done = true;
			}else if(event.data == YT.PlayerState.PAUSED){
				$('#video_status').val('paused');		
			}
		};
		var stopVideo = function(){
			if(applicationGoogle.getYoutubePlayer() != undefined){
				applicationGoogle.getYoutubePlayer().stopVideo();
			}
		};
		var pauseVideo = function(){
			if(applicationGoogle.getYoutubePlayer() != undefined){
				applicationGoogle.getYoutubePlayer().pauseVideo();
			}
		};
		
		var startVideo = function(){

			if(applicationGoogle.getYoutubePlayer() != undefined){
				applicationGoogle.getYoutubePlayer().playVideo();
			}else{
				var video_prop_id	= $('#video_id').val();
				var video_height 	= $('#video_height');
				var video_width 	= $('#video_height');

				player = new YT.Player('player', {
					height: (video_height.length > 0) ? video_height.val() : '462',
					width: (video_width.length > 0) ? video_width.val() : '640',
					videoId: video_prop_id,
					events: {
						'onReady': onPlayerReady,
						'onStateChange': onPlayerStateChange
					}
				});
				applicationGoogle.getYoutubePlayer().playVideo();
			}
		};

		var formatCurrency = function(num, val) {
			num = num.toString().replace(/\$|\,/g,'');
			if(isNaN(num))
				num = "0";

			sign = (num == (num = Math.abs(num)));
			num = Math.floor(num*100+0.50000000001);
			cents = num % 100;
			num = Math.floor(num/100).toString();	

			if(cents<10)
				cents = "0" + cents;

			for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
				num = num.substring(0,num.length-(4*i+3))+','+
			num.substring(num.length-(4*i+3));
				
			if(val.is('input')){
				val.val((((sign)?'':'-') + '$' + num + '.' + cents));
			}else{
				val.html((((sign)?'':'-') + '$' + num + '.' + cents));
			}
		};

		var displayNotification = function(object){
			$(object.container).html(Handlebars.partials['partial_bars'](object.configuration));
    		$(object.container).show();
		};

		var navBarHover = function(){
			$('.navigationbar ul.nav li.dropdown:not(.mega-dropdown)').hover(
		 		function(){
			  		$(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
				}, 
				function(){
			  		$(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
				}
			);	
		};

		var apiLogin = function(data){
			
			if(data.sent_api)
				data.api = data.sent_api;

			window['angularComponentRef'].component.apiLogin(data);
        };

        var goBack = function(data){
			window.history.back();
        };

        var refreshSearchBanner = function(){   

        	if (window['angularComponentRef'] && typeof window['angularComponentRef'].component.searchPageCheck === "function") {     	
				window['angularComponentRef'].component.searchPageCheck();
			}
        };

        var setInternalSearch = function(searchObject){

        	if (typeof window['internalSearchComponentRef'].component.setSearchParams === "function") {
    			window['internalSearchComponentRef'].component.setSearchParams(searchObject);
			}
        };

        var updateNavigation = function(){        	
        	if (typeof window['navigationComponentRef'].component.checkUser === "function") {
    			window['navigationComponentRef'].component.checkUser();
			}
        };

        function configureTips(){
			$('.backtoTop a').click(function(e){
		        e.preventDefault();
		        $('html, body').animate({ scrollTop: $("body").offset().top }, 2000);
		    });
			
		}

		function configureFaqs(){
			$('.backtoTop a').click(function(e){
		        e.preventDefault();
		        $('html, body').animate({ scrollTop: $("body").offset().top }, 2000);
		    });
		}

		var scrollTo = function(){
			$(document).on('click', '.scrollTo', function(e) {
				e.preventDefault();
				// target element id
				var id = $(this).attr('href');
				
				// target element
				var $id = $(id);
				if ($id.length === 0) {
					return;
				}
				
				// prevent standard hash navigation (avoid blinking in IE)
				e.preventDefault();

			  // top position relative to the document
			  var pos = $(id).offset().top;

			  // animated top scrolling
			  $('body, html').animate({scrollTop: pos});
			});
		}

		var configureMember = function(){
			$(document).on('click', '.memberButtons > a', function(e) {
				e.preventDefault();
				$('.memberButtons').addClass('open');
			});
			$('body').click(function(evt){    
		       if(evt.target.id == "memberButtons")
		          return;
		       //For descendants of menu_content being clicked, remove this check if you do not want to put constraint on descendants.
		       if($(evt.target).closest('#memberButtons').length)
		          return;             

		      	$('.memberButtons').removeClass('open');
		  	});
		}

		function slideFade(elem) {
		   var fade = { opacity: 0, transition: 'opacity 0.5s' };
		   elem.css(fade).slideUp();
		}

		var closeVideoSection = function(){
			$(document).on('click', '.close_video_window',function(){
		    	slideFade($('.video_block'));	
		    	stopVideo();
		    	event.target.stopVideo();
		    	$('#video_status').val('stop');
		    });
		};

		var netSliderBox = function(){
			/*
			$('.nstSlider').nstSlider({
	            "left_grip_selector": ".leftGrip",
	            "right_grip_selector": ".rightGrip",
	            "value_bar_selector": ".bar",
	            "value_changed_callback": function(cause, leftValue, rightValue) {

	            	switch( $('#rent_sale').val() ){
	            		case 'rent':
	            		$(this).parent().find('.leftLabel').text(leftValue + ' K');
		                $(this).parent().find('.rightLabel').text(rightValue + ' K');

		                $('#price_from').val( parseFloat(leftValue) * 1000 );

		                $('#price_to').val( parseFloat(rightValue) * 1000 );

	            		break;
	            		case 'sale':
	            		case 'both':
	            		$(this).parent().find('.leftLabel').text(leftValue + ' M');
		                $(this).parent().find('.rightLabel').text(rightValue + ' M');

		                $('#price_from').val( parseFloat(leftValue) * 1000000 );

		                $('#price_to').val( parseFloat(rightValue) * 1000000 );

	            		break;
	            		default:
	            		$(this).parent().find('.leftLabel').text(leftValue + ' M');
		                $(this).parent().find('.rightLabel').text(rightValue + ' M');

		                $('#price_from').val( parseFloat(leftValue) * 1000000 );

		                $('#price_to').val( parseFloat(rightValue) * 1000000 );
	            		break;
		            }
	            }
	        });
			*/
			var valueCheck = "";
			var multiplier = 1;
			switch( $('#rent_sale').val() ){
        		case 'rent':
        		valueCheck = "K";
        		multiplier = 1000;
                //$('#price_from').val( parseFloat(leftValue) * 1000 );
                //$('#price_to').val( parseFloat(rightValue) * 1000 );
        		break;
        		case 'sale':
        		case 'both':
        		valueCheck = "M";
        		multiplier = 1000000;

        		if( $('#currency').val() !== 'JMD' && $('#currency').val() !== '' ){
        			multiplier = 1000;
        			valueCheck = "K";

        		}
                //$('#price_from').val( parseFloat(leftValue) * 1000000 );
                //$('#price_to').val( parseFloat(rightValue) * 1000000 );
        		break;
        		default:
        		valueCheck = "M";
        		multiplier = 1000000;

        		if( $('#currency').val() !== 'JMD' && $('#currency').val() !== '' ){
        			multiplier = 1000;
        			valueCheck = "K";

        		}
                //$('#price_from').val( parseFloat(leftValue) * 1000000 );
                //$('#price_to').val( parseFloat(rightValue) * 1000000 );
        		break;
            }

            

            $('#range_search').html('');

            $('#range_search2').html('');

			$(".range-slider-ui").each(function () {
		        var minValue = $(this).attr('data-min');
		        var maxValue = $(this).attr('data-max');
		        var unit = $(this).attr('data-unit');
		        $(this).append("<input type='hidden' value='' id='price_from' /><input type='hidden' value='' id='price_to' /><input type='text' class='min-value' disabled/><input type='text' class='max-value' disabled/>");
		        $(this).slider({
		            range: true,
		            min: minValue,
		            max: maxValue,
		            values: [minValue, maxValue],
		            slide: function (event, ui) {
		                event = event;

		                $('#price_from').val(ui.values[0] * multiplier);
		                $('#price_to').val(ui.values[1] * multiplier);

		                $(this).children(".min-value").val(ui.values[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + " " + valueCheck + " " + unit);
		                $(this).children(".max-value").val(ui.values[1].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + " " + valueCheck + " " + unit );
		            }
		        });
		        $(this).children(".min-value").val($(this).slider("values", 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + " " + valueCheck + " " + unit );
		        $(this).children(".max-value").val($(this).slider("values", 1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + " " + valueCheck + " " + unit );
		    });
		};

		resetSliderBox = function(parameters){
			netSliderBox();
			/*
			switch( $('#rent_sale').val() ){
	        	case 'rent':
	        	$('.nstSlider').nstSlider('set_range', 0,parameters.max);

	        	$('.nstSlider').nstSlider('set_position', 0,parameters.max);
	        	break;
	        	case 'sale':
	           	case 'both':
	           	$('.nstSlider').nstSlider('set_range', 0,parameters.max);
	           	$('.nstSlider').nstSlider('set_position', 0,parameters.max);
	           	break;
	        }
	        */
		};

		changeBreadCrumb = function(crumbObject){
			if(window['angularBreadCrumbComponentRef'] && window['angularBreadCrumbComponentRef'].component)
				window['angularBreadCrumbComponentRef'].component.submitBreadCrumb(crumbObject);
		};


		disableBreadCrumbTitle = function(){
			if(window['angularBreadCrumbComponentRef'] && window['angularBreadCrumbComponentRef'].component)
				window['angularBreadCrumbComponentRef'].component.disableBreadCrumbText();
		};

		selectPicker = function(){
			$('.selectpicker').selectpicker('refresh');
		};

		var propertyScroll = function(parameters){
			parameters = JSON.parse(parameters);
			if(parameters[0] === "MLS"){
				$('html, body').animate({
        			scrollTop: $('.result_item_scroll').filter('[data-mls="'+parameters[1]+'"]').offset().top
    			}, 2000);

			}
			else{
				if($('.result_item_scroll').filter('[data-listing="'+parameters[1]+'"]').offset()){
					$('html, body').animate({
	        			scrollTop: $('.result_item_scroll').filter('[data-listing="'+parameters[1]+'"]').offset().top
	    			}, 2000);
				}
			}
			localStorage.removeItem('previousListing');
		};

		carouselSlider = function(parameters){
			var $featuredMyCarousel = $(parameters.element);

	        var $firstFeaturedAnimatingElems = $featuredMyCarousel.find('.item:first').find("[data-animation ^= 'animated']");
	        //Initialize carousel
	        $featuredMyCarousel.carousel();

	        //Animate captions in first slide on page load
	        doAnimations($firstFeaturedAnimatingElems);
	        //Pause carousel
	        $featuredMyCarousel.carousel('pause');
	        //Other slides to be animated on carousel slide event
	        $featuredMyCarousel.on('slide.bs.carousel', function (e) {
	            var $featuredAnimatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
	            doAnimations($featuredAnimatingElems);
	        });
	        $('#carousel-example-featured').carousel({
	            interval: 10000,
	            pause: "false"
	        });
		}
		doAnimations = function(elems){
            //Cache the animationend event in a variable
            var animEndEv = 'webkitAnimationEnd animationend';
            elems.each(function () {
                var $this = $(this),
                    $animationType = $this.data('animation');
                $this.addClass($animationType).one(animEndEv, function () {
                    $this.removeClass($animationType);
                });
            });
        }

        var configureGeneralDatePicker = function(){
        	$.datetimepicker.setDateFormatter({
			    parseDate: function (date, format) {
			        var d = moment(date, format);
			        return d.isValid() ? d.toDate() : false;
			    },
			    formatDate: function (date, format) {
			        return moment(date).format(format);
			    },
			});
			$('.general_date_picker').datetimepicker({
				timepicker:false,
				format:'YYYY-MM-DD'
			});
		};

		var searchFieldConfigure = function(){


			$('#search_from').keyup(function(){
				formatCurrencyField( document.getElementById("search_from") );
			});

			$('#search_to').keyup(function(){
				formatCurrencyField( document.getElementById("search_to") );
			});

			$('#search_from_commercial').keyup(function(){
				formatCurrencyField( document.getElementById("search_from_commercial") );
			});

			$('#search_to_commercial').keyup(function(){
				formatCurrencyField( document.getElementById("search_to_commercial") );
			});			

        	$(document).on('click', '.res_com_button , .res_com_button_commercial ',function(e){
		    	e.preventDefault();

		    	$('.res_com_button').each(function(){
		    		$(this).removeClass('active');
		    	});
		    	$(this).addClass('active');

		    	$('#residential_commercial_hidden').val($(this).data('value'));
		    	$('#residential_commercial_hidden_commercial').val($(this).data('value'));


		    	switch($(this).data('value')){
            		case 'commercial':
            		case 'residential':
            		var parameters = {
						targetField: $('#property_type_search'),
						data: {
					    	res_com: $(this).data('value'),
					    },
					    url: '/ajax/property-type'
					};
					getSelectOptions(parameters);

					var parameters = {
						targetField: $('#property_type_search_commercial'),
						data: {
					    	res_com: $(this).data('value'),
					    },
					    url: '/ajax/property-type'
					};
					getSelectOptions(parameters);

            		break;
            		default:
            		var parameters = {
						targetField: $('#property_type_search'),
						data: {
					    	res_com: '',
					    },
					    url: '/ajax/property-type'
					};
					getSelectOptions(parameters);
            		break;
            	}

		    	
            	
            	

            	switch($(this).data('value')){
            		case 'commercial':
            		$('#bed_search').val('');
            		$('#bath_search').val('');
            		$('#bed_search').attr('disabled', 'disabled');
            		$('#bath_search').attr('disabled', 'disabled');

            		$('#bed_container').hide();
					$('#bath_container').hide();

					$('#main_search_residential_container').hide();
					$('#main_search_commercial_container').show();
					$('#main_search_commercial_container').removeClass("hidden");

					$('#main_search_commercial_container #search_category_button_commercial').addClass('active');

					console.log($('#main_search_commercial_container'));
					console.log($('#main_search_residential_container'));

            		break;

            		default:
            		$('#bed_container').show();
            		$('#bath_container').show();

            		$('#main_search_residential_container #search_category_button_' + $(this).data('value').toLowerCase() ).addClass('active');

            		$('#bed_search').attr('disabled', false);
            		$('#bath_search').attr('disabled', false);

            		$('#main_search_residential_container').show();
            		$('#main_search_commercial_container').hide();
            		break;
            	}
		    });

		    $(document).on('change', '#rent_sale_search_commercial',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#price_range_search_commercial'),
					data: {
				    	currency: $('#currency_search_commercial').val(),
				    	rent_sale: $(this).val()
				    },
				    url: '/ajax/price-range'
				};

		    	getSelectOptions(parameters);

		    });

		    $(document).on('change', '#currency_search_commercial',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#price_range_search_commercial'),
					data: {
				    	currency: $('#currency_search_commercial').val(),
				    	rent_sale: $('#rent_sale_search_commercial').val()
				    },
				    url: '/ajax/price-range'
				};

		    	getSelectOptions(parameters);	    	

		    });

		    $(document).on('change', '#rent_sale_search',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#price_range_search'),
					data: {
				    	currency: $('#currency_search').val(),
				    	rent_sale: $(this).val()
				    },
				    url: '/ajax/price-range'
				};
		    	getSelectOptions(parameters);
		    });

		    $(document).on('click', '.rent_sale_button',function(e){
		    	e.preventDefault();

		    	$('.rent_sale_button').each(function(){
		    		$(this).removeClass('active');
		    	});
		    	$(this).addClass('active');

		    	$('#rent_sale_hidden').val($(this).data('value'));

		    	var parameters = {
					targetField: $('#price_range_search'),
					data: {
				    	currency: $('#currency_search').val(),
				    	rent_sale: $('#rent_sale_hidden').val()
				    },
				    url: '/ajax/price-range'
				};

		    	getSelectOptions(parameters);

		    });

		    $(document).on('change', '#parish_search',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#town_search'),
					data: {
				    	parish: $(this).val(),
				    },
				    url: '/ajax/towns'
				};

		    	getSelectOptions(parameters);

		    });

		    $(document).on('change', '#limit_filter',function(e){
		    	$('#search_limit').val($(this).val());
		    	$('#search_range').val($('#range_filter').val());
		    	$('#internal_main_search').submit();
		    });

		    $(document).on('change', '#rent_sale_small',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#price_range_search_sm'),
					data: {
				    	currency: $('#currency_small').val(),
				    	rent_sale: $(this).val()
				    },
				    url: '/ajax/price-range'
				};
		    	getSelectOptions(parameters);
		    });

		    $(document).on('change', '#range_filter',function(e){
		    	$('#search_range').val($(this).val());
		    	$('#search_limit').val($('#limit_filter').val());
		    	$('#internal_main_search').submit();
		    });

		    $(document).on('change', '#res_com_small',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#property_type_small'),
					data: {
				    	res_com: $(this).val(),
				    },
				    url: '/ajax/property-type'
				};            	
            	getSelectOptions(parameters);
		    });


		    $(document).on('change', '#currency_search',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#price_range_search'),
					data: {
				    	currency: $('#currency_search').val(),
				    	rent_sale: $('#rent_sale_search').val()
				    },
				    url: '/ajax/price-range'
				};

		    	getSelectOptions(parameters);

		    });

		    $(document).on('change', '#currency_small',function(e){
		    	e.preventDefault();

		    	var parameters = {
					targetField: $('#price_range_search_sm'),
					data: {
				    	currency: $('#currency_small').val(),
				    	rent_sale: $('#rent_sale_small').val()
				    },
				    url: '/ajax/price-range'
				};

		    	getSelectOptions(parameters);	    	

		    });

		    $(document).on('click', '#reset_search',function(e){
		    	e.preventDefault();

		    	$( "#main_search" + " :input[type='text'], "+"#main_search"+" :input[type='email'], "+"#main_search"+" select").each(function(){
					$(this).val('');
				});
				$('.res_com_button').each(function(){
		    		$(this).removeClass('active');
		    	});
		    	$('.rent_sale_button').each(function(){
		    		$(this).removeClass('active');
		    	});

		    	$('#residential_commercial_hidden').val("");
		    	$('#rent_sale_hidden').val("");

		    });
		};

		var createAjaxRequest = function(parameters, callBack){
        	setAjaxRequest($.ajax({
	                type:parameters.request_type,
	                url: parameters.url,
	                dataType: parameters.data_type,
	                data: parameters.data,
            	})
        	);
	        getAjaxRequest().then(
	            function(data){	            	
	                if(callBack){
	                	var callBackVariable = 'resolved';
	                	if(parameters.return_data)
	                		callBackVariable = data;
                		callBack(callBackVariable);
	                }
	            },
	            function(data){                    
	                if(callBack){
	                    callBack('resolved');
	                }
	            }
	        );
        };	

        var getSelectOptions = function(parameters){
        	parameters.targetField.html('');
    		var duplicatePromise = new Promise(function(resolve, reject){
                utilities.createAjaxRequest(
                    {
                        url: parameters.url,
                        request_type: 'POST',
                        data_type: 'json',
                        data: parameters.data,
                        return_data: 1
                    },
                    resolve
                );
            }).then(function(data){
            	data.selection_options = 1;
            	parameters.targetField.html(Handlebars.partials['partial_bars'](data));		

            	selectPicker();				
            	
            });			
		};

		var formatCurrencyField = function(ctrl){
			if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40){
				return;
			}

			var val = ctrl.value;

			val = val.replace(/,/g, "")
			ctrl.value = "";
			val += '';
			x = val.split('.');
			x1 = x[0];
			x2 = x.length > 1 ? '.' + x[1] : '';

			var rgx = /(\d+)(\d{3})/;

			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}

			ctrl.value = x1 + x2;		
		};

        return {
            init: function(){
                try{

                	$('[data-toggle="slide-collapse"]').on('click', function() {
                		$navMenuCont = $($(this).data('target'));
                		$navMenuCont.animate({
                			'width': 'toggle'
                		}, 350);
                		$(".menu-overlay").fadeIn(500);

                		$('#small-menu-button').hide();
                		$('#slide-navbar-collapse').show();

                		$('#slide-navbar-collapse').css("cssText", "display:block !important;");

                	});
                	$(".menu-overlay").click(function(event) {
                		$(".navbar-toggle").trigger("click");
                		$(".menu-overlay").fadeOut(500);
                		$('#small-menu-button').show();
                		$('#slide-navbar-collapse').hide();
                	});

                	$(document).on( 'click','#small-menu-button-hide', function(e){

				    	console.log(e);

						//$(".navbar-toggle").trigger("click");
                		$(".menu-overlay").fadeOut(500);
                		$('#small-menu-button').show();
                		$('#slide-navbar-collapse').hide();
				    });
                	
                	Swag.registerHelpers(Handlebars);
                	renderPage();
                	JqueryValidateAdditionals();

                	searchFieldConfigure();

                }
                catch(e){
                    console.error(e.stack);
                }
            },
            requiredUlCheck: function(requiredObject,requiredFields,notificationDiv){
            	requiredUlCheck(requiredObject,requiredFields,notificationDiv);
            },
            ajaxDuplicateCheck: function(dataType,requestType,urlLocation,serializedData,duplicateSpan,hiddenElement,elementDiv,toggleLoadingDiv,toggleAuthenticate){
                ajaxDuplicateCheck(dataType,requestType,urlLocation,serializedData,duplicateSpan,hiddenElement,elementDiv,toggleLoadingDiv,toggleAuthenticate);
            },
            setAjaxRequest: function(value){
                setAjaxRequest(value);
            },
            getAjaxRequest: function(){
                return getAjaxRequestPull();
            },
            setModalConfirm: function(value){
            	setModalConfirm(value);
            },
            getModalConfirm: function(){
            	return getModalConfirm();
            },
            isEmptyObject: function(object){
            	return isEmptyObject(object);
            },
            loadingModal: function(state){
            	loadingModal(state);
            },
            resetElements : function(elements){
            	resetElements(elements);
            },
            displayContainer : function(object){
            	displayContainer(object);
            },
            errorFocus: errorFocus,

            closeModal : function(object){
            	closeModal(object);
            },
            configureInstagram: configureInstagram,
            getYoutubeList: getYoutubeList,
            formatCurrency: formatCurrency,
            displayNotification: displayNotification,
            stickHead:stickHead,
          	navBarHover: navBarHover,
          	addBodyClass: addBodyClass,
          	removeBodyClass: removeBodyClass,
          	apiLogin: function(data){
                apiLogin(data);
            },
            goBack:goBack,
            configureTips: configureTips,
          	configureFaqs: configureFaqs,
            refreshSearchBanner: refreshSearchBanner,
            setInternalSearch: setInternalSearch,
            configureMember: configureMember,
            updateNavigation: updateNavigation,
            closeVideoSection: closeVideoSection,
            scrollTo: scrollTo,
            netSliderBox: netSliderBox,
            resetSliderBox: resetSliderBox,
            changeBreadCrumb: changeBreadCrumb,
            disableBreadCrumbTitle: disableBreadCrumbTitle,
            selectPicker: selectPicker,
            propertyScroll: propertyScroll,
            carouselSlider: carouselSlider,
            configureGeneralDatePicker: configureGeneralDatePicker,
            searchFieldConfigure: searchFieldConfigure,
            createAjaxRequest: createAjaxRequest,
            getSelectOptions: getSelectOptions,
            getAjaxRequest: getAjaxRequest, 
            formatCurrencyField: formatCurrencyField
        };
    };
})();